.react-pdf__Page__annotations {
    height: 10px !important;
}

/* .react-pdf__Page__canvas {
    width: 100% !important;
} */

.pdf_doc {
    padding-left: 23%;
    padding-right: 23%;
}


@media screen and (max-width: 480px) {
    .pdf_doc {
        padding-left: 1rem;
        padding-right: 0;
    }

    /* .react-pdf__Page__canvas {
        width: 46% !important;
        height: 360px !important;
    } */

    .react-pdf__Page__textContent {
        width: 400px !important;
    }
}


/* Base styles for all devices */
.pdf-iframe-container {
    width: 100%;
    height: 100% !important; /* Adjust height as needed */
    border: none;
    overflow: hidden;
}

.pdf-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

/* Styles for smaller devices like phones */
@media only screen and (max-width: 600px) {
    .pdf-iframe-container {
        height: 100vh; /* Full viewport height for better viewing on small screens */
    }

    .pdf-iframe {
        height: 100vh; /* Full viewport height for better viewing on small screens */
    }
}

/* Styles for tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .pdf-iframe-container {
        height: 100vh; /* Full viewport height for better viewing on tablets */
    }

    .pdf-iframe {
        height: 100vh; /* Full viewport height for better viewing on tablets */
    }
}

/* Styles for larger devices like desktops */
@media only screen and (min-width: 1025px) {
    .pdf-iframe-container {
        height: 100%; /* Use 100% of container height on larger screens */
    }

    .pdf-iframe {
        height: 100%; /* Use 100% of container height on larger screens */
    }
}